<template>
  <div class="brandlist">
    <!-- <van-nav-bar
      title="品牌列表"
      left-arrow
      @click-left="onClickLeft"
    /> -->
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
      shape="round"
    >
      <template #left>
        <div @click="onClickLeft">
          <van-icon name="arrow-left" size="16px" />
        </div>
      </template>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getlist"
      :immediate-check="false"
    >
      <van-grid :column-num="3" icon-size="40px">
        <van-grid-item
          v-for="(item, index) in list"
          :key="index"
          :icon="item.thumb"
          :text="item.name"
          :to="{name: 'List', query: {brandid: item.id, shareid: shareid, merchid: merchid }}"
        />
      </van-grid>
    </van-list>
  </div>
</template>
<script>
export default {
  name: "BrandList",
  data() {
    return {
      shareid: 0,
      merchid: 0,
      size: 45,
      offset: 0,
      loading: false,
      finished: false,
      nodata: false,
      list: [],
      keywords: '',
    }
  },
  mounted() {
    console.log("mounted");
    this.showback = this.$route.query.showback
      ? this.$route.query.showback
      : false;
    this.goods = [];
    this.strId = localStorage.getItem("DK_STRID");
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "品牌列表");
    this.init();
  },
  activated() {
    console.log("activated");
  },
  methods: {
    init() {
      console.log("init");
      this.getlist();
    },
    onClickLeft() {
      this.$router.back();
    },
    getlist() {
      let _that = null;
      _that = this;
      if (_that.offset == 0) {
        _that.$toast({
          type: "loading",
          message: "加载中...",
          forbidClick: true,
          duration: 0
        })
      }
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/goods/get_brand_list",
          _that.$qs.stringify({
            size: _that.size,
            offset: _that.offset,
            merchid: _that.merchid,
            keywords: _that.keywords
          })
        )
        .then(res => {
          console.log(res);
          _that.loading = false;
          _that.nodata = false;
          _that.$toast.clear();
          if (res.data.code == 100000) {
            if (_that.offset == 0) {
              _that.list = res.data.data;
            }else {
              res.data.data.forEach(item => {
                _that.list.push(item);
              })

            }

            _that.offset += res.data.data.length;
            if (res.data.data.length < _that.size) {
              _that.finished = true;
            }else {
              _that.finished = false;
            }
          }else {
            _that.finished = true;
            if (_that.offset == 0) {
              _that.nodata = true;
            }
          }
          
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 执行搜索
    onSearch() {
      this.offset = 0;
      this.getlist();
    },
  },
}
</script>
<style lang="less">
  .brandlist {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    background-color: #fff;
    .van-search {
      padding-left: 0;
      .van-icon-arrow-left {
        padding: 0 16px;
      }
      .van-search__action {
        padding: 0 14px;
      }
      .van-cell__value {
        flex: 1;
      }
    }
    .van-list {
      padding-bottom: 54px;
    }
    .van-grid-item__icon {
      height: 40px;
      width: 40px;
      border-radius: 4px;
      overflow: hidden;
      display: block;
    }
    .van-grid-item__text {
      font-size: 12px;
      line-height: 16px;
      height: 32px;
      text-align: center;
    }
  }
</style>